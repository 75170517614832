@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Libre+Baskerville&family=Source+Serif+Pro&display=swap');

body {
    font-family: "Merriweather", serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    min-height: 100vh;

    overflow-x: hidden;
    background: url("../public/background3.webp") repeat top center;
}

body::before {
    content: " ";
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    opacity: 0.8;
}

:where(h1, h2, h3, h4, h5) {
    font-family: "Source Code Pro", serif;
    font-weight: bold;
    margin-top: 0;
}

:where(a, a:visited) {
    color: #fff;
}

h1 {
    margin-bottom: 1rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
    /*text-align: justify;*/
    word-break: break-word;

}

.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    z-index: 1;
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit.fade-exit-active {
    opacity: 0;
}

li {
    margin-bottom: 1rem;
}
